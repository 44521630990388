import { useState, useContext } from "react";
import { NavLink } from "react-router-dom";

import { formatDate } from "../../util/format-date";
import { msToHMS } from "../../util/time-helpers";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";

import { AuthedUserContext } from "../../store/authed-user-context";

import DeleteWork from "./Delete";
import { isBrowser } from "react-device-detect";

import Button from "react-bootstrap/Button";

function WorkItem({ work }) {
  const { isAdmin, _id: userId } = useContext(AuthedUserContext);

  const [isNew, setIsNew] = useState(
    new Date() - new Date(work.createdAt) < 10000 ? true : false,
  );

  setTimeout(() => {
    setIsNew(false);
  }, 15000);

  return (
    <>
      <Card
        className={`shadow-sm mb-3 ${isNew ? "bg-success bg-opacity-10" : ""}`}
      >
        <Card.Body>
          <Row className="mb-2">
            <Col>
              <Image
                src={
                  work.finishedBy.profileImagePath
                    ? `${process.env.REACT_APP_ADDRESS}/uploads/${work.finishedBy.profileImagePath}`
                    : "/profilepic-placeholder.jpg"
                }
                style={{ maxHeight: "30px" }}
                className="me-2"
                roundedCircle
              />
              <strong>{`${work?.finishedBy?.lastName} ${work?.finishedBy?.firstName}`}</strong>{" "}
              <span className="text-body-secondary">{`${formatDate(
                work.createdAt,
              )}`}</span>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              {work.visitRequired && (
                <span>
                  Произведён <strong>выезд</strong>.{" "}
                </span>
              )}
              {!work.visitRequired && (
                <span>
                  Проведены <strong>удалённые работы</strong>.{" "}
                </span>
              )}
              {work.description}
            </Col>
          </Row>
          <Row className="justify-content-end align-items-end">
            <Col className="mb-3">
              Длительность{" "}
              <strong>
                {msToHMS(new Date(work.finishedAt) - new Date(work.startedAt))}
              </strong>
            </Col>
            {(isAdmin || work.createdBy?._id.toString() === userId) && (
              <>
                {isBrowser && (
                  <Col xs="auto" className="text-end">
                    <DeleteWork work={work} />
                  </Col>
                )}
                <Col xs="auto" className="text-end">
                  <Button
                    as={NavLink}
                    to={`work/${work._id.toString()}/update`}
                  >
                    Изменить
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default WorkItem;
