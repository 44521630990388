import { Outlet, useNavigation, Link, useNavigate } from "react-router-dom";

import { BrowserView, MobileView } from "react-device-detect";

import { RiRefreshLine } from "react-icons/ri";

import AddButton from "./AddButton";

import Transitions from "../animations/Transition";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

import Spinner from "../animations/Spinner";
import AlertMessage from "./AlertMessage";
import SearchBar from "./SearchBar";

import useOffcanvasStore from "../store/offcanvas";
import Select from "./Select";
import { useState } from "react";

const ListWrapper = ({
  title,
  filter,
  customData,
  filterStore,
  addRoute,
  hiddenAddButton,
  children,
}) => {
  const { state } = useNavigation();
  const navigate = useNavigate();

  const Filter = () => filter;

  const isLoading = filterStore.isLoading || filterStore.isSorting;

  const offcanvas = useOffcanvasStore();

  const searchHandler = (e) => {
    const query = e.target.value;
    filterStore.fullTextSearch(query);
  };

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const showOffcanvasChangeHandler = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  return (
    <>
      <Card.Title className="mb-3 border-bottom">
        <h1 className="display-4">{title()}</h1>
      </Card.Title>
      <BrowserView>
        <Row>
          <Col sm="auto">
            <Button as={Link} replace to="." size="lg" className="w-100 mb-3">
              <RiRefreshLine />
            </Button>
          </Col>
          {!hiddenAddButton && (
            <Col sm="auto">
              <AddButton
                onClick={offcanvas.setShow}
                content="Добавить"
                to={addRoute ? addRoute : "add"}
              />
            </Col>
          )}
          <Col>
            <SearchBar onChange={searchHandler} />
          </Col>
        </Row>
      </BrowserView>
      <MobileView>
        <Row className="mb-3">
          <Col xs={6}>
            <Button as={Link} replace to="." size="lg" className="w-100">
              <RiRefreshLine /> Обновить
            </Button>
          </Col>
          <Col xs={6}>
            <AddButton content="Добавить" to={addRoute ? addRoute : "add"} />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <SearchBar onChange={searchHandler} />
          </Col>
        </Row>
      </MobileView>
      {customData ? customData() : ""}
      <MobileView>
        <Offcanvas
          show={showOffcanvas}
          onHide={() => {
            setShowOffcanvas(false);
          }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Фильтр</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Filter
              items={filterStore.originalList}
              setShowOffcanvas={setShowOffcanvas}
            />
          </Offcanvas.Body>
        </Offcanvas>
        <Row className="justify-content-between my-3  fs-5">
          <Col>{`Найдено: ${filterStore.filteredList?.length || 0}`}</Col>
        </Row>
        <Row className="justify-content-between align-items-center my-3 fs-6">
          <Col xs={7}>
            <Select
              placeholder="Сортировка"
              defaultValue={filterStore.sortBy}
              options={filterStore.sortingOptions}
              getOptionLabel={(option) => `${option.label}`}
              getOptionValue={(option) => option.label}
              onChange={(selectedItem) =>
                filterStore.handleSorting(selectedItem)
              }
            />
          </Col>
          <Col
            className="text-end text-success"
            onClick={showOffcanvasChangeHandler}
          >
            <u>Фильтр</u>
          </Col>
        </Row>
      </MobileView>
      <BrowserView>
        <Row className="justify-content-between my-3 fs-6 align-items-end">
          <Col>{`Найдено: ${filterStore.filteredList?.length || 0}`}</Col>
          <Col sm={3}>
            <Select
              placeholder="Сортировка"
              defaultValue={filterStore.sortBy}
              options={filterStore.sortingOptions}
              getOptionLabel={(option) => `${option.label}`}
              getOptionValue={(option) => option.label}
              onChange={(selectedItem) =>
                filterStore.handleSorting(selectedItem)
              }
            />
          </Col>
        </Row>
      </BrowserView>
      {(filterStore.filteredList?.length > 0 ||
        filterStore.originalList?.length > 0) && (
        <>
          {state === "idle" && !isLoading && (
            <Transitions>{children}</Transitions>
          )}
          {(state === "loading" || isLoading) && (
            <Transitions>
              <Spinner />
            </Transitions>
          )}
        </>
      )}
      {filterStore.filteredList?.length === 0 &&
        filterStore.originalList?.length === 0 &&
        !isLoading && <AlertMessage variant="light" message="Список пуст" />}
      <Offcanvas
        show={offcanvas.isActive}
        onHide={() => {
          navigate(-1);
          offcanvas.setClose();
        }}
        keyboard
        placement="bottom"
        className="h-100"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Outlet />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ListWrapper;
