import { useState, useEffect, useCallback, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import useUserFilterStore from "../../store/lists/users";

import FilterContainer from "../../UI/FilterContainer";

const ServicePlanFilter = ({
  setShowOffcanvas = () => {
    return null;
  },
}) => {
  const filterStore = useUserFilterStore();

  const resetFilterHandler = () => {
    filterStore.resetFilter();
  };

  const getListLengthBy = (list, itemName, item) => {
    let result = [];
    if (itemName === "company") {
      result = list.filter((user) => user.company?._id === item._id).length;
    }

    return result;
  };

  return (
    <FilterContainer
      setShowOffcanvas={setShowOffcanvas}
      resetFilterHandler={resetFilterHandler}
    ></FilterContainer>
  );
};
export default ServicePlanFilter;
