import { useState, useEffect, useContext } from "react";

import { NavLink } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";

import Logout from "../components/Auth/Logout";

import { getLocalStorageData } from "../util/auth";

import {
  RiAccountBoxLine,
  RiServerLine,
  RiBuilding2Line,
  RiSettings3Line,
  RiDraftLine,
  RiArchiveLine,
  RiUserLine,
  RiUserSettingsLine,
  RiFileList2Line,
  RiDashboard2Line,
  RiCalendar2Line,
  RiCoinLine,
  RiServiceLine,
  RiDeviceLine,
} from "react-icons/ri";
import { GoProjectTemplate } from "react-icons/go";
import { MdOutlineDarkMode } from "react-icons/md";

import { FaNetworkWired } from "react-icons/fa";
import { IoHardwareChipOutline } from "react-icons/io5";
import { TbCheckbox } from "react-icons/tb";

import { AuthedUserContext } from "../store/authed-user-context";
import useInitialPrefs from "../store/prefs";

const NavigationBar = ({ handleShowAuthModal }) => {
  const { token, darkMode } = getLocalStorageData();
  const { modules } = useInitialPrefs();
  const { isAdmin, firstName, lastName, dashboard, permissions } =
    useContext(AuthedUserContext);

  const {
    canManageTicketCategories,
    canManageCompanies,
    canManageUsers,
    canManageRoutineTasks,
    canSeeWorksReport,

    canUseTimeTrackingModule,
    canUseInventoryModule,
    canManageMikrotikDevices,
    canManageClientDevices,
    canUseFinancesModule,
    canManageServicePlans,
  } = permissions;

  const isLoggedIn = !!token;

  const [showOffcanvas, setShowOffcanvas] = useState("false");

  const handleClose = () => setShowOffcanvas("false");
  const handleShow = () => setShowOffcanvas("true");

  const UserDropdownTitle = () => {
    return (
      <label htmlFor="theme">
        <RiUserLine /> {`${firstName} ${lastName}`}
      </label>
    );
  };

  const ListsDropdownTitle = () => {
    return (
      <>
        <RiFileList2Line /> Списки
      </>
    );
  };

  const ReportsDropdownTitle = () => {
    return (
      <>
        <RiDraftLine /> Отчёты
      </>
    );
  };

  const FinancesDropdownTitle = () => {
    return (
      <>
        <RiCoinLine /> Финансы
      </>
    );
  };

  const TemplatesDropdownTitle = () => {
    return (
      <>
        <GoProjectTemplate /> Шаблоны
      </>
    );
  };

  const InventoryDropdownTitle = () => {
    return (
      <>
        <IoHardwareChipOutline /> Оборудование
      </>
    );
  };

  const DarkModeSwitchTitle = () => {
    return (
      <>
        <MdOutlineDarkMode />
      </>
    );
  };

  const [darkModeToggler, setDarkModeToggler] = useState(darkMode);

  const darkModeTogglerHandler = () => {
    setDarkModeToggler(!darkModeToggler);
    window.location.reload();
  };

  useEffect(() => {
    if (darkModeToggler) {
      localStorage.setItem("darkMode", true);
    } else {
      localStorage.setItem("darkMode", false);
    }
  }, [darkModeToggler]);

  return (
    <Navbar
      expand="xxl"
      bg={darkMode ? "dark" : "primary"}
      className="navbar-dark fixed-top mb-4"
    >
      <Container fluid>
        <Navbar.Toggle
          aria-controls={`offcanvasNavbar-expand-lg`}
          onClick={handleShow}
        />
        <Navbar.Brand as={NavLink} to="/" id="logo">
          <img alt="" src="/logo.png" className="d-inline-block align-top" />
        </Navbar.Brand>
        <Navbar.Offcanvas
          show={showOffcanvas === "true" ? "true" : ""}
          onHide={handleClose}
          id={`offcanvasNavbar-expand-lg`}
          aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
          placement="start"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
              Меню
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-start flex-grow-1 pe-3">
              {isLoggedIn && (
                <>
                  <Nav.Link
                    hidden={!dashboard?.isActive}
                    as={NavLink}
                    to="/dashboard"
                    onClick={handleClose}
                  >
                    <RiDashboard2Line /> Dashboard
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/tickets" onClick={handleClose}>
                    <TbCheckbox /> Заявки
                  </Nav.Link>
                  {permissions.canPerformTickets && !canManageCompanies && (
                    <Nav.Link
                      as={NavLink}
                      to="/companies"
                      onClick={handleClose}
                    >
                      <RiAccountBoxLine /> Компании
                    </Nav.Link>
                  )}
                  {permissions.canPerformTickets && !canManageUsers && (
                    <Nav.Link as={NavLink} to="/users" onClick={handleClose}>
                      <RiAccountBoxLine /> Пользователи
                    </Nav.Link>
                  )}
                  <NavDropdown title={<TemplatesDropdownTitle />}>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/ticket-templates"
                      onClick={handleClose}
                    >
                      Шаблоны заявок
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    hidden={
                      !isAdmin &&
                      !canManageTicketCategories &&
                      !canManageCompanies &&
                      !canManageUsers &&
                      !canManageRoutineTasks
                    }
                    title={<ListsDropdownTitle />}
                  >
                    <NavDropdown.Item
                      as={NavLink}
                      hidden={!canManageTicketCategories}
                      to="/ticket-categories"
                      onClick={handleClose}
                    >
                      <RiServerLine /> Категории заявок
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      hidden={!canManageCompanies}
                      to="/companies"
                      onClick={handleClose}
                    >
                      <RiBuilding2Line /> Компании
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      hidden={!canManageUsers && !isAdmin}
                      to="/users"
                      onClick={handleClose}
                    >
                      <RiAccountBoxLine /> Пользователи
                    </NavDropdown.Item>
                    {canManageRoutineTasks && (
                      <NavDropdown.Item
                        as={NavLink}
                        hidden={!canManageRoutineTasks}
                        to="/routine-tasks"
                        onClick={handleClose}
                      >
                        <RiCalendar2Line /> Регламентные задания
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>
                  {(modules.timeTracking.isActive ||
                    modules.inventory.isActive) && (
                    <NavDropdown
                      hidden={!canSeeWorksReport && !canManageMikrotikDevices}
                      title={<ReportsDropdownTitle />}
                    >
                      {canUseTimeTrackingModule &&
                        modules.timeTracking.isActive && (
                          <NavDropdown.Item
                            as={NavLink}
                            hidden={!canSeeWorksReport}
                            to="/report/work"
                            onClick={handleClose}
                          >
                            <RiDraftLine /> Отчёт по работам
                          </NavDropdown.Item>
                        )}
                      {canUseInventoryModule && modules.inventory.isActive && (
                        <NavDropdown.Item
                          hidden={!canManageMikrotikDevices}
                          as={NavLink}
                          to="/report/networks"
                          onClick={handleClose}
                        >
                          <RiDraftLine /> Диапазоны сетей
                        </NavDropdown.Item>
                      )}
                    </NavDropdown>
                  )}
                  {modules?.finances.isActive && canUseFinancesModule && (
                    <NavDropdown title={<FinancesDropdownTitle />}>
                      <NavDropdown.Item
                        as={NavLink}
                        hidden={!canManageServicePlans}
                        to="/finances/service-plans"
                        onClick={handleClose}
                      >
                        <RiServiceLine /> Услуги
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="/finances/summary-report"
                        onClick={handleClose}
                      >
                        <RiDraftLine /> Согласование отчётов
                      </NavDropdown.Item>
                    </NavDropdown>
                  )}
                  {modules?.inventory.isActive && canUseInventoryModule && (
                    <NavDropdown title={<InventoryDropdownTitle />}>
                      <NavDropdown.Item
                        as={NavLink}
                        hidden={!canManageClientDevices}
                        to="/inventory/client-devices"
                        onClick={handleClose}
                      >
                        <RiDeviceLine /> Устройства
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        as={NavLink}
                        hidden={!canManageMikrotikDevices}
                        to="/devices/mikrotik"
                        onClick={handleClose}
                      >
                        <FaNetworkWired /> Устройства Mikrotik
                      </NavDropdown.Item>
                    </NavDropdown>
                  )}
                  {isAdmin && (
                    <Nav.Link
                      as={NavLink}
                      to="/preferences"
                      onClick={handleClose}
                    >
                      <RiSettings3Line /> Настройки
                    </Nav.Link>
                  )}
                  <Nav.Link
                    as={NavLink}
                    to="/closed-tickets"
                    onClick={handleClose}
                  >
                    <RiArchiveLine /> Архив заявок
                  </Nav.Link>
                </>
              )}
            </Nav>
            <Nav>
              {isLoggedIn && (
                <>
                  <Nav.Link>
                    <Form.Check
                      type="switch"
                      id="theme"
                      checked={darkModeToggler}
                      onChange={darkModeTogglerHandler}
                      label={<DarkModeSwitchTitle />}
                    />
                  </Nav.Link>
                  <NavDropdown title={<UserDropdownTitle />} align="end">
                    <NavDropdown.Item
                      as={NavLink}
                      to="/my-account"
                      onClick={handleClose}
                    >
                      <RiUserSettingsLine /> Мой аккаунт
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <Logout handleShowAuthModal={handleShowAuthModal} />
                  </NavDropdown>
                </>
              )}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
