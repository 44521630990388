import { useLoaderData } from "react-router-dom";

import { formatPrice } from "../../util/format-string";

import useSummaryReportFilterStore from "../../store/finances/report";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import { HiOutlineMagnifyingGlass } from "react-icons/hi2";

import { formatShortDate } from "../../util/format-date";

const PaidTable = () => {
  const filterStore = useSummaryReportFilterStore();

  const { paid } = useLoaderData();

  const tariffingPlans = {
    hourPackage: "Пакеты часов",
    hourly: "Почасовая оплата",
    fixedPrice: "Фиксированная оплата",
  };

  return (
    <>
      {filterStore.statuses.includes("paid") && (
        <>
          <h1 className="display-5">Ожидает выставления счёта</h1>
          <Table className="ms-1" bordered>
            <thead>
              <tr>
                <th>Компания</th>
                <th>Услуга</th>
                <th>Период</th>
                {/* {<th>Тариф</th> */}
                <th>Дата оплаты</th>
                <th className="text-end">Сумма</th>
                <th>Действия</th>
              </tr>
            </thead>
            <tbody>
              {paid
                .sort(
                  (aReport, bReport) =>
                    new Date(aReport.periodFrom).getTime() -
                    new Date(bReport.periodFrom).getTime(),
                )
                .map((report) => (
                  <tr key={report._id}>
                    <td>{report.company.fullTitle}</td>
                    <td>{report.servicePlan.title}</td>
                    <td>{`${formatShortDate(report.periodFrom)} - ${formatShortDate(report.periodTo)}`}</td>
                    {/* <td>
                      {tariffingPlans[report.servicePlan?.tariffing?.type]}
                    </td> */}
                    <td>{formatShortDate(report.invoice.fullyPaidAt)}</td>
                    <td className="text-end">
                      {report.additionalPrice
                        ? `${formatPrice(report.price)} + ${formatPrice(report.additionalPrice)} = ${formatPrice(report.price + report.additionalPrice)}`
                        : formatPrice(report.price)}
                    </td>
                    <td>
                      <Button
                        onClick={() => {}}
                        variant="primary"
                        size="sm"
                        className="m-1"
                      >
                        <HiOutlineMagnifyingGlass />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default PaidTable;
