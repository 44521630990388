import { useState } from "react";
import { Form as RouterForm } from "react-router-dom";

import { calculateOvertime, calculateWorkTime } from "../../util/finances";

import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import DetailedViewOffcanvas from "./DetailedViewOffcanvas";

const TableActionBar = ({
  plan,
  data,
  amount,
  relatedWorks,
  unrelatedWorks,
}) => {
  const schedule = plan.companyWorkSchedule
    ? data.company.workSchedule
    : plan.customProvisionSchedule;

  const [canConfirmReport, setCanConfirmReport] = useState();
  return (
    <>
      {unrelatedWorks.length > 0 && (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              В списке не должно быть выполненных работ, не привязанных ни к
              одной услуге
            </Tooltip>
          }
        >
          <span className="d-inline-block">
            <Button
              size="sm"
              variant="success"
              disabled
              style={{ pointerEvents: "none" }}
            >
              Утвердить
            </Button>
          </span>
        </OverlayTrigger>
      )}
      {unrelatedWorks.length === 0 && (
        <RouterForm method="post" className="d-inline-block">
          <input
            name="relatedWorks"
            defaultValue={JSON.stringify(relatedWorks)}
            hidden
          />
          <input name="companyId" defaultValue={data.company._id} hidden />
          <input name="servicePlanId" defaultValue={plan._id} hidden />
          <input name="price" defaultValue={amount.price} hidden />
          <input
            name="additionalPrice"
            defaultValue={amount.additionalPrice}
            hidden
          />
          <Button
            size="sm"
            type="submit"
            variant="success"
            name="intent"
            value="confirmReportByContractor"
          >
            Утвердить
          </Button>
        </RouterForm>
      )}
      <DetailedViewOffcanvas
        worktimeWorks={
          calculateWorkTime(schedule, relatedWorks, plan.tariffing.period)
            .worktimeWorks
        }
        overtimeWorks={
          calculateOvertime(schedule, relatedWorks, plan.tariffing.period)
            .overtimeWorks
        }
      />
    </>
  );
};

export default TableActionBar;
