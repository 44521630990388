import { create } from "zustand";

import { getLocalStorageData } from "../../util/auth";

const servicePlanFilter = (state) => {
  const originalList = state.originalList ? state.originalList : [];
  return originalList.filter((item) => {
    if (state.searchTerm.length > 0) {
      return [
        item.title,
        ...item.ticketCategories?.flatMap((category) => [category.title]),
        ...item.companies?.flatMap((company) => [company.alias]),
      ]
        .join(" ")
        .toLowerCase()
        .includes(state.searchTerm);
    } else {
      return true;
    }
  });
};

const searchItems = (query, items) => {
  if (!query) return items;

  // Split the query into individual terms (e.g., "Ольга Вознюк" becomes ["Ольга", "Вознюк"])
  const queryTerms = query.toLowerCase().split(" ").filter(Boolean);

  return items.filter((item) => {
    const fieldsToSearch = [
      item.title,
      JSON.stringify(item.companies),
      JSON.stringify(item.categories),
    ];

    return queryTerms.every((term) =>
      fieldsToSearch.some(
        (field) => field && field.toLowerCase().includes(term),
      ),
    );
  });
};

const useServicePlanFilterStore = create((set) => ({
  provisionSchedule: "any",
  ticketCategories: [],
  companies: [],
  tariffPlan: "any",
  searchTerm: "",
  originalList: [],
  filteredList: [],
  fullTextSearch: (query) =>
    set((state) => ({
      filteredList: searchItems(query, servicePlanFilter(state)),
    })),
  isLoading: false,
  fetch: async () => {
    set({ isLoading: true });
    const { token } = getLocalStorageData();
    const response = await fetch(
      `${process.env.REACT_APP_ADDRESS}/api/service-plans`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    );
    const data = await response.json();
    set({
      originalList: data,
      isLoading: false,
    });
  },
  applyFilter: () =>
    set((state) => ({ filteredList: servicePlanFilter(state) })),
  resetFilter: () => {
    set(() => ({
      provisionSchedule: "any",
      ticketCategories: [],
      companies: [],
      tariff: "any",
      searchTerm: "",
    }));
    set((state) => ({
      filteredList: servicePlanFilter(state),
    }));
  },
}));

export default useServicePlanFilterStore;
